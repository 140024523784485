<template>
  <div>
    <div class="orderDetailTab pt-6">
      <div class="grid grid-cols-12">
        <div class="col-span-3">Şipariş No: {{ order.id }}</div>
        <div class="col-span-3">Oluşturma Tarihi: {{ order.date }}</div>
        <div class="col-span-3">Toplam KG: {{ order.totalKG }}</div>
        <div class="col-span-3">Tutar: {{ order.price }}$</div>
      </div>
      <vs-divider></vs-divider>

      <div>
        <div class="grid grid-cols-12">
          <div
            class="col-span-2 orderDetailProducts"
            v-for="product in order.products"
            :key="product.id"
          >
            <img
              :src="product.image"
              alt=""
              style="
                width: 100%;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
              "
            />

            <div class="flex justify-between pl-2 pr-2 pb-2">
              <div>Kg : {{ product.kg }}</div>
              <div>Qty : {{ product.quantity }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orderNo: null,
      order: null,
      fakeData: [
        {
          id: 1,
          date: "12.12.2021",
          totalKG: 5,
          price: 130,
          products: [
            {
              description: "Kedi 1",
              id: 1,
              image: "https://placekitten.com/600/600",
              kg: 1,
              price: 100,
              quantity: 6,
              title: "Product 1",
            },
            {
              description: "Kedi 2 ",
              id: 2,
              image: "https://placekitten.com/600/600",
              kg: 1,
              price: 300,
              quantity: 5,
              title: "Product 2",
            },
          ],
        },
        {
          id: 2,
          date: "12.12.2021",
          totalKG: 5,
          price: 130,
          products: [
            {
              description: "Kedi 1",
              id: 1,
              image: "https://placekitten.com/600/600",
              kg: 1,
              price: 100,
              quantity: 6,
              title: "Product 1",
            },
            {
              description: "Kedi 2 ",
              id: 2,
              image: "https://placekitten.com/600/600",
              kg: 1,
              price: 300,
              quantity: 5,
              title: "Product 2",
            },
          ],
        },
        {
          id: 3,
          date: "12.12.2021",
          totalKG: 5,
          price: 130,
          products: [
            {
              description: "Kedi 1",
              id: 1,
              image: "https://placekitten.com/600/600",
              kg: 1,
              price: 100,
              quantity: 6,
              title: "Product 1",
            },
            {
              description: "Kedi 2 ",
              id: 2,
              image: "https://placekitten.com/600/600",
              kg: 1,
              price: 300,
              quantity: 5,
              title: "Product 2",
            },
          ],
        },
        {
          id: 4,
          date: "12.12.2021",
          totalKG: 5,
          price: 130,
          products: [
            {
              description: "Kedi 1",
              id: 1,
              image: "https://placekitten.com/600/600",
              kg: 1,
              price: 100,
              quantity: 6,
              title: "Product 1",
            },
            {
              description: "Kedi 2 ",
              id: 2,
              image: "https://placekitten.com/600/600",
              kg: 1,
              price: 300,
              quantity: 5,
              title: "Product 2",
            },
          ],
        },
        {
          id: 5,
          date: "12.12.2021",
          totalKG: 5,
          price: 130,
          products: [
            {
              description: "Kedi 1",
              id: 1,
              image: "https://placekitten.com/600/600",
              kg: 1,
              price: 100,
              quantity: 6,
              title: "Product 1",
            },
            {
              description: "Kedi 2 ",
              id: 2,
              image: "https://placekitten.com/600/600",
              kg: 1,
              price: 300,
              quantity: 5,
              title: "Product 2",
            },
          ],
        },
      ],
    };
  },
  methods: {
    InitOrder() {
      if (this.$route.query.no != null) {
        this.orderNo = this.$route.query.no;
      } else {
        this.$router.push("/shop_orders");
      }

      let index = this.fakeData.findIndex((e) => e.id == this.orderNo);

      this.order = this.fakeData[index];
    },
  },
  created() {
    this.InitOrder();
  },
};
</script>

<style scoped>
.orderDetailTab {
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);

  font-weight: 700;
}
.orderDetailProducts {
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 10px;
}
</style>
